import "./App.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

function App() {
  return (
    <div className="text-center">
      <div className="relative flex h-screen w-full flex-col justify-between overflow-hidden pb-[20px] sm:overflow-visible lg:justify-center lg:gap-32">
        {/* <h1 className="font-mono text-2xl uppercase">Sophie & Daniel</h1> */}
        <div className="relative mx-auto w-full max-w-[1080px]">
          <div className="absolute -ml-16 w-[600px] sm:ml-0 sm:w-full">
            <img
              loading="eager"
              alt=""
              src="https://media-api.xogrp.com/images/c7966e0b-e7bd-4b45-8c46-0cbd52fba47f~rt_auto-rs_768.h?ordering=explicit"
              className="w-full"
            />
          </div>

          {/* <div className="top-1/2 left-0 right-0 sm:absolute sm:left-1/2 sm:-translate-x-1/2"> */}
          <div className="mt-[25vh]">
            <hr className="my-10 mx-auto w-40 border-t-2 border-[#d99a91]" />
            <h1 className="marry font-['GreatVibes'] text-7xl ">
              Sophie, will you <br />
              marry me?
            </h1>
            <hr className="my-10 mx-auto w-40 border-t-2 border-[#d99a91]" />
          </div>
          {/* <h2 className="font-['GreatVibes'] text-4xl  text-[#656F6E]">Sophie</h2> */}
        </div>
        <div className="">
          <Swiper
            slidesPerView="auto"
            breakpoints={{
              768: { slidesPerView: 2.2 },
              1024: { slidesPerView: 2.6 },
              1440: { slidesPerView: 3.2 },
            }}
          >
            <SwiperSlide>
              <img id="img1" alt="love her" src="/img/swiper-1.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img2" alt="love her" src="/img/swiper-2.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img3" alt="love her" src="/img/swiper-3.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img4" alt="love her" src="/img/swiper-4.jpg" />
            </SwiperSlide>
            {/* <SwiperSlide>
            <img id="img5" alt="love her" src="/img/swiper-5.jpg" />
          </SwiperSlide> */}
            <SwiperSlide>
              <img id="img6" alt="love her" src="/img/swiper-6.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img7" alt="love her" src="/img/swiper-7.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img8" alt="love her" src="/img/swiper-8.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img id="img9" alt="love her" src="/img/swiper-9.jpg" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="flex h-screen flex-1 flex-col justify-center px-4">
        <div>
          <div>
            <hr className="my-10 mx-auto w-40 border-t-2 border-[#d99a91]" />
            <h2 className="marry font-['GreatVibes'] text-7xl ">Poema</h2>
            <hr className="my-10 mx-auto w-40 border-t-2 border-[#d99a91]" />
          </div>
          <div className="font-mono text-sm font-semibold text-[#914c3f] lg:text-lg">
            Sophie, minha querida,
            <br />
            O que seria a vida sem ti?
            <br />
            Seria sem graça, sem sentido.
            <br />
            Então eu orei, esperei, ao Senhor pedi,
            <br />
            Pedi com fé que me concedesse este pedido,
            <br />
            De puder partilhar um vida contigo.
            <br />
            E aqui estou eu, de joelhos a ti rendido,
            <br />
            Pois desejo fazer-te feliz, de ti cuidar,
            <br />
            Prometo tomar atenção, nos pormenores me aplicar. <br />
            Levar-te a dates, namorar, perder a noção das horas,
            <br />
            Acordar ao teu lado, encher-te de palavras amorosas.
            <br />
            Porém nem sempre irá ser perfeito,
            <br />
            Somos duas pessoas diferentes, cada um com o seu jeito.
            <br />
            Mas eu prometo amar-te até o fim.
            <br />O que só falta é tu dizeres que{" "}
            <u className="font-bold uppercase">sim</u>!
          </div>
        </div>
      </div>
      <div className="flex-none py-10">
        <span className="text-[#d99a91]">Yours truly,</span>
        <br />
        <span className="text-[#914c3f]">Daniel Simão ❤️</span>
        <br />
        <span className="italic text-[#914c3f]">15th of August 2022</span>
      </div>
    </div>
  );
}

export default App;
